import axios from 'axios';

const URL = '/api/v1/Domains/CheckSpace';

const getSpaceSubdomain = async (subdomain) => {
  const subdomainJSON = JSON.stringify({
    auth: 'gy94tpW49fT5kk.$dlworyyfhsaurF734.%',
    subdomain,
  });
  const response = await axios.post(URL, subdomainJSON);

  return response.data;
};

export default { getSpaceSubdomain };
