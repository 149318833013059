/* eslint-disable default-case */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CognitoRefreshToken,
  AuthenticationDetails,
  CookieStorage,
  CognitoUser,
} from 'amazon-cognito-identity-js';

const initialState = {
  session: null,
  status: {
    setSession: 'idle',
    authenticate: 'idle',
    resendVerificationCode: 'idle',
  },
  error: {
    resendVerificationCode: null,
    setSession: null,
    refreshSession: null,
    logout: null,
    authenticate: null,
  },
};

export const setSession = createAsyncThunk(
  'session/setSession',
  async (_, { getState }) => {
    return await new Promise((resolve, reject) => {
      const { pool } = getState().pool;
      const user = pool.getCurrentUser();

      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject(err);
          } else {
            session.isValid()
              ? resolve(session)
              : resolve(refreshToken(user, session));
          }
        });
      } else {
        reject(false);
      }
    });
  },
);

export const refreshToken = createAsyncThunk(
  'session/refreshToken',
  async (user, session) => {
    const refreshToken = new CognitoRefreshToken({
      RefreshToken: session.getRefreshToken().getToken(),
    });

    return await new Promise((resolve, reject) => {
      user.refreshSession(refreshToken, (err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  },
);

export const logout = createAsyncThunk(
  'session/logout',
  async (_, { getState }) => {
    const { pool } = getState().pool;
    const user = pool.getCurrentUser();

    if (user) {
      user.signOut();

      window.location.href = `${
        window.location.protocol
      }//${window.location.host.replace('account.', '')}`;
    }
  },
);

export const authenticate = createAsyncThunk(
  'session/authenticate',
  async (credentials, { getState }) => {
    return await new Promise((resolve, reject) => {
      const { pool } = getState().pool;
      const { Username, Password } = credentials;
      const user = setUser(Username, pool);

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (result) => {
          resolve(result);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (result) => {
          console.log('newPasswordRequired: ', result);
          resolve(result);
        },
      });
    });
  },
);

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(setSession.pending, (state) => {
        state.status.setSession = 'loading';
      })
      .addCase(setSession.fulfilled, (state, action) => {
        const session = action.payload;

        state.status.setSession = 'succeeded';
        state.session = session;
      })
      .addCase(setSession.rejected, (state, action) => {
        console.log('error fetching session: ', action.error);

        state.status.setSession = 'failed';
        state.error.setSession = action.error.message;
      })
      .addCase(authenticate.pending, (state) => {
        console.log('loading authenticate');

        state.status.authenticate = 'loading';
      })
      .addCase(authenticate.fulfilled, (state, action) => {
        const session = action.payload;

        state.status.authenticate = 'succeeded';
        state.error.authenticate = null;
        state.session = session;
      })
      .addCase(authenticate.rejected, (state, action) => {
        state.status.authenticate = 'failed';
        state.error.authenticate = action.error.name;
      })
      .addCase(logout.fulfilled, (state) => {
        state.status.logout = 'succeeded';
        state.status.authenticate = 'idle';
        state.status.setSession = 'idle';
        state.session = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.error.logout = action.error.name;
      })
      .addCase(resendVerificationCode.fulfilled, (state) => {
        state.status.resendVerificationCode = 'succeeded';
      })
      .addCase(resendVerificationCode.rejected, (state, action) => {
        state.error.resendVerificationCode = action.error.name;
      });
  },
});

export const resendVerificationCode = createAsyncThunk(
  'session/resendVerificationCode',
  async (email, { getState }) => {
    const pool = getState().pool;
    const user = setUser(email, pool);

    const response = await user.resendConfirmationCode();

    return response;
  },
);

export const setUser = (Username, Pool) => {
  console.log('CookieStorage. point C');
  return new CognitoUser({
    Username,
    Pool,
    Storage: new CookieStorage({
      secure: false,
      //sameSite: 'none',
      domain: window.location.host.replace(':3000', '').replace(':3001', ''),
    }),
  });
};

//selectors
export const selectSession = (state) => state.session.session;
export const getSessionStatus = (state) => state.session.status.setSession;
export const getSessionError = (state) => state.session.error.setSession;
export const getAuthenticateStatus = (state) =>
  state.session.status.authenticate;
export const getAuthenticateError = (state) => state.session.error.authenticate;

export default sessionSlice.reducer;
