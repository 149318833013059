import { configureStore } from '@reduxjs/toolkit';
import iconNavSlice from 'redux/iconNavSlice';
import sessionSlice from './features/sessionSlice';
import domainSlice from './features/domainSlice';
import poolSlice from './features/poolSlice';

export const store = configureStore({
  reducer: {
    iconNav: iconNavSlice,
    session: sessionSlice,
    domain: domainSlice,
    pool: poolSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
