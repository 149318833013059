import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import domainService from 'services/domain';

const initialState = {
  domain: '',
  status: 'idle',
  error: null,
};

export const getDomainInfo = createAsyncThunk(
  'domain/getDomainInfo',
  async (domain) => {
    const info = await domainService.getSpaceSubdomain(domain);

    return info;
  },
);

const domainSlice = createSlice({
  name: 'domain',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDomainInfo.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getDomainInfo.fulfilled, (state, action) => {
        const domainInfo = action.payload;

        state.status = 'succeeded';
        state.domain = domainInfo;
      })
      .addCase(getDomainInfo.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error;
      });
  },
});

export const selectDomain = (state) => state.domain.domain;
export const getDomainStatus = (state) => state.domain.status;

export default domainSlice.reducer;
