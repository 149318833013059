import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { emailInputControl, passwordInputControl } from 'helpers/authControl';
//redux
import { authenticate } from 'redux/features/sessionSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAuthenticateError,
  getAuthenticateStatus,
} from 'redux/features/sessionSlice';
//components/ui
import { Input, FormNotificationError } from 'components';
import useField from 'hooks/useField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import { green } from '@mui/material/colors';

export default function SignInForm({ setStage, emailField }) {
  const dispatch = useDispatch();
  const { t } = useTranslation(['signIn', 'translation']);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const authenticateError = useSelector(getAuthenticateError);
  const authenticateStatus = useSelector(getAuthenticateStatus);
  // const navigate = useNavigate();
  const passwordField = useField('password');

  useEffect(() => {
    if (authenticateError !== null) {
      switch (authenticateError) {
        case 'NotAuthorizedException':
          setShowError(true);
          break;
        case 'UserNotConfirmedException':
          // handleNotVerifiedAccount();
          setStage('emailVerification');
          break;
      }
    }
  }, [authenticateError]);

  useEffect(() => {
    if (authenticateStatus === 'succeeded') {
      const decodedURI = decodeURIComponent(window.location.search);
      const params = new URLSearchParams(decodedURI);
      const redirect = params.get('redirect');

      redirect ? (window.location = redirect) : (window.location = '/');
    }
  }, [authenticateStatus]);

  const inputFormControl = () => {
    const email = emailField.atributes.value;
    const password = passwordField.atributes.value;
    let areValid = true;

    if (emailInputControl(email)) {
      emailField.actions.onSuccess();
    } else {
      emailField.actions.onError(
        <FormNotificationError
          errorMsg={t('error.errorEmail', { ns: 'signIn' })}
        />,
      );

      areValid = false;
    }

    if (passwordInputControl(password)) {
      passwordField.actions.onSuccess();
    } else {
      passwordField.actions.onError(
        <FormNotificationError
          errorMsg={t('error.errorPassword', { ns: 'signIn' })}
        />,
      );

      areValid = false;
    }

    return areValid;
  };

  // const handleNotVerifiedAccount = () => {
  //   const email = emailField.atributes.value;

  //   resendVerificationCode(email);
  //   setStage('emailVerification');
  // };

  const onSubmit = async (event) => {
    event.preventDefault();
    const email = emailField.atributes.value;
    const password = passwordField.atributes.value;

    setShowError(false);
    setLoading(true);

    if (inputFormControl()) {
      await dispatch(authenticate({ Username: email, Password: password }));
    }

    setLoading(false);
  };

  return (
    <form onSubmit={onSubmit}>
      {showError && (
        <Fade in={showError}>
          <Box display={'flex'} color="red" alignItems={'center'} mb={3}>
            <FormNotificationError
              errorMsg={t('error.errorEmailPassword', { ns: 'signIn' })}
            />
          </Box>
        </Fade>
      )}
      <Grid container spacing={4}>
        <Input
          label={t('global.enterEmail', { ns: 'translation' })}
          atributes={emailField.atributes}
        />
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'stretched', sm: 'center' }}
            justifyContent={'flex-end'}
            width={1}
            marginBottom={2}
          >
            <Typography variant={'subtitle2'}>
              <Link
                component={'a'}
                color={'primary'}
                href={'/forgotpassword'}
                underline={'none'}
              >
                {t('login.forgotPassword', { ns: 'signIn' })}
              </Link>
            </Typography>
          </Box>
          <Input
            label={t('global.enterPassword', { ns: 'translation' })}
            atributes={passwordField.atributes}
          />
        </Grid>
        <Grid item container xs={12}>
          <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{ xs: 'stretched', sm: 'center' }}
            justifyContent={'end'}
            width={1}
            maxWidth={600}
            margin={'0 auto'}
          >
            <Button
              style={{ minWidth: 140 }}
              size={'large'}
              variant={'contained'}
              type={'submit'}
            >
              {loading ? (
                <CircularProgress size={26} sx={{ color: green[300] }} />
              ) : (
                t('global.login', { ns: 'translation' })
              )}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
}

SignInForm.propTypes = {
  setStage: PropTypes.func.isRequired,
  emailField: PropTypes.object.isRequired,
};
