/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const AppList = () => {
  // const theme = useTheme();
  const { t } = useTranslation('indexView');

  const mock = [
    {
      title: 'Trii Team',
      subtitle: t('benefits.subtitleTriiTeam'),
      description: t('benefits.descriptionTriiTeam'),
      icon: '/img/triiModules/TriiTeam.svg',
      link: '/a/team'
    },
    {
      title: 'Trii Chat',
      subtitle: t('benefits.subtitleTriiChat'),
      description: t('benefits.descriptionTriiChat'),
      icon: '/img/triiModules/TriiChat.svg',
      link: '/a/chat'
    },
    {
      title: 'Trii Marketing',
      subtitle: t('benefits.subtitleTriiMarketing'),
      description: t('benefits.descriptionTriiMarketing'),
      icon: '/img/triiModules/TriiMarketing.svg',
      link: '/a/marketing'
    },
    {
      title: 'Trii Ticket',
      subtitle: t('benefits.subtitleTriiTicket'),
      description: t('benefits.descriptionTriiTicket'),
      icon: '/img/triiModules/TriiTickets.svg',
      link: '/a/tickets'
    },
    {
      title: 'Trii Service',
      subtitle: t('benefits.subtitleTriiService'),
      description: t('benefits.descriptionTriiService'),

      icon: '/img/triiModules/TriiService.svg',
      link: '/a/service'
    },
    {
      title: t('benefits.titleTriiCollections'),
      subtitle: t('benefits.subtitleTriiCollections'),
      description: t('benefits.descriptionTriiCollections'),
      icon: '/img/triiModules/TriiCobranzas.svg',
      link: '/a/collection'
    },
    {
      title: t('benefits.titleTriiCalls'),
      subtitle: t('benefits.subtitleTriiCalls'),
      description: t('benefits.descriptionTriiCalls'),
      icon: '/img/triiModules/TriiCall.svg',
      link: '/a/calls'
    },
    {
      title: t('benefits.titleTriiContacts'),
      subtitle: t('benefits.subtitleTriiContacts'),
      description: t('benefits.descriptionTriiContacts'),
      icon: '/img/triiModules/TriiContacts.svg',
      link: '/a/contacts'
    },
    {
      title: t('tools.titleTriiCalendar'),
      icon: '/img/triiModules/TriiCalendar.svg',
      link: '/a/calendar'
    },
    {
      title: 'Trii CRM',
      icon: '/img/triiModules/TriiCRM.svg',
      link: '/a/CRM'
    },
  ];
  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '150px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h2"
          style={{
            fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
            lineHeight: '1.2222222222222223',
            marginTop: '10px',
            marginBottom: '10px',
          }}
          fontWeight={800}
        >
          {t('benefits.categoriesTitle')}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={i}
          >
            <a href={item.link} style={{textDecoration: 'none'}}>
              <Box
                component={Card}
                padding={2}
                borderRadius={2}
                width={1}
                // height={1}
                data-aos={'fade-up'}
                data-aos-delay={i * 100}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <Box
                    component="img"
                    // width={50}
                    height={50}
                    // marginBottom={2}
                    // bgcolor={theme.palette.primary.main}
                    // color={theme.palette.background.paper}
                    src={item.icon}
                  ></Box>

                  <Typography
                    variant={'h4'}
                    color={'primary'}
                    gutterBottom
                    sx={{ fontWeight: 600, fontSize: '1.1243rem !important' }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </Box>
            </a>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default AppList;
