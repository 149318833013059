import React from 'react';

import {
  IndexView,
  SignInView,
  ResetPasswordView,
  ContactView,
  // TriiChatView,
  // TriiCollectionView,
  // TriiContactsView,
  // TriiLlamadasView,
  // TriiMarketingView,
  // TriiServiceView,
  // TriiTeamView,
  // TriiTicketsView,
  FuncionesView,
  AppsView,
  TicketsView,
  EditTicket,
  FormNewTicket
} from 'views';

const routes = [
  {
    path: '/',
    renderer: (params = {}) => <IndexView {...params} />,
  },
  {
    path: '/signin',
    renderer: (params = {}) => <SignInView {...params} />,
  },
  {
    path: '/forgotpassword',
    renderer: (params = {}) => <ResetPasswordView {...params} />,
  },
  {
    path: '/contacto',
    renderer: (params = {}) => <ContactView {...params} />,
  },
  {
    path: '/funciones',
    renderer: (params = {}) => <FuncionesView {...params} />,
  },
  {
    path: '/apps',
    renderer: (params = {}) => <AppsView {...params} />,
  },
  {
    path: '/tickets',
    renderer: (params = {}) => <TicketsView {...params} />,
  },
  {
    path: '/tickets/editTicket/1',
    renderer: (params = {}) => <EditTicket {...params} />,
  },
  {
    path: '/tickets/newTicket',
    renderer: (params = {}) => <FormNewTicket {...params} />,
  },
];

export default routes;
