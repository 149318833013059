import React, {useContext} from 'react'; //eslint-disable-line
import { Box, Button, InputAdornment, TextField } from '@mui/material';
// import { useTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
// import { StyleContext } from '../../../../style/styleProvider';
import { useNavigate } from 'react-router-dom';


import { useTheme } from '@mui/material';
const TicketMenu = () => {
  //   const { t } = useTranslation('indexView');
//   const { shadow } = useContext(StyleContext);
  const theme = useTheme();
  const navigate = useNavigate(); //eslint-disable-line

  const openFormNewTicket = () => {
    navigate('/tickets/newTicket');
    console.log('open');
  };
 

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      sx={{ paddingX: 2 }}
      borderRadius={'4px'}
      bgcolor={theme.palette.background}
      height='50px'
      className='m-0'
    //   boxShadow={shadow.md}
    >
      <TextField
        id='input-with-icon-textfield'
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <SearchIcon sx={{fontSize:'18px'}}/>
            </InputAdornment>
          ),
        }}
        variant='outlined'
        sx={{
          '& legend': { display: 'none' },
          '& fieldset': { top: 0 },
          '& .MuiInputBase-input': {height: '0.8375em'},
        //   height: '30px'
        }}
        size='small'
        placeholder='search by...'
        
      />
      <Button style={{ alignSelf: 'center' }} variant='contained' size='small' onClick={openFormNewTicket}>
        Crear Ticket
      </Button>
      {/* Modales */}
    </Box>
  );
};

export default TicketMenu;
