import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Page from './components/Page';
import StyleProvider from 'style/styleProvider';

import './i18n'; //Traduccion;

import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';

import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import 'aos/dist/aos.css';

//redux
import { store } from './redux/store';
import { Provider } from 'react-redux';

const App = () => {
  return (
    <Provider store={store}>
      <StyleProvider>
        <Suspense fallback={null}>
          <Page>
            <I18nextProvider i18n={i18next}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </I18nextProvider>
          </Page>
        </Suspense>
      </StyleProvider>
    </Provider>
  );
};

export default App;
