import React, { useEffect } from 'react';
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom'; //eslint-disable-line
import { IndexView } from 'views';
import viewsRoutes from 'views/routes';

//redux
import { setPool, selectPool } from 'redux/features/poolSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDomainInfo,
  getDomainStatus,
  selectDomain,
} from 'redux/features/domainSlice';
import { setSession } from 'redux/features/sessionSlice';

const Routes = () => {
  const dispatch = useDispatch();
  const domainStatus = useSelector(getDomainStatus);
  const domain = useSelector(selectDomain);
  const pool = useSelector(selectPool);

  useEffect(() => {
    const domain =
      window.location.hostname === 'localhost'
        ? 'empresa1.mytrii.app'
        : window.location.host;

    dispatch(getDomainInfo(domain));
  }, []);

  useEffect(() => {
    if (domainStatus === 'succeeded') {
      dispatch(setPool(domain));
    }
  }, [domainStatus]);

  useEffect(() => {
    if (pool !== null) {
      dispatch(setSession());
    }
  }, [pool]);

  return (
    <>
      {pool !== null && (
        <ReactRoutes>
          {viewsRoutes.map((item, i) => (
            <Route key={i} path={item.path} element={item.renderer()} />
          ))}
          <Route path="*" element={<IndexView />} />
        </ReactRoutes>
      )}
    </>
  );
};

export default Routes;
