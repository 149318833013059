import { createSlice } from '@reduxjs/toolkit';
import { CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';

const initialState = {
  pool: null,
};

const poolSlice = createSlice({
  name: 'pool',
  initialState,
  reducers: {
    setPool: {
      reducer(state, action) {
        state.pool = new CognitoUserPool(action.payload);
      },
      prepare(poolData) {
        const { ClientId, UserPoolId } = poolData;

        console.log('CookieStorage. point B');
        return {
          payload: {
            UserPoolId,
            ClientId,
            Storage: new CookieStorage({
              secure: false,
              //sameSite: 'none',
              domain: window.location.host
                .replace(':3000', '')
                .replace(':3001', ''),
            }),
          },
        };
      },
    },
  },
});

export const selectPool = (state) => state.pool.pool;
export const { setPool } = poolSlice.actions;

export default poolSlice.reducer;
