import React, { useState, useEffect, useContext } from 'react'; //eslint-disable-line
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { NavItem } from './components';
import { useTranslation } from 'react-i18next';
//redux
import { useSelector, useDispatch } from 'react-redux';
import { selectIcon } from '../../../../redux/iconNavSlice';
import { selectSession, logout } from '../../../../redux/features/sessionSlice';
//components/ui
import NavButton from '../../../../components/NavButton';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import ThemeModeToggler from 'components/ThemeModeToggler';
import LenguageSelect from '../../../../components/LenguageSelect';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NavLink } from 'react-router-dom';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { mode } = theme.palette;
  const navigate = useNavigate();
  const navigateTo = (event, url) => {
    event.preventDefault();
    navigate(url);
  };
  // const { mode } = theme.palette;
  const {
    homeMenu: home,
    ticketsMenu: tickets,
    serviceMenu: service,
    contactMenu: contacts,
  } = pages;
  const session = useSelector(selectSession);
  const iconNav = useSelector(selectIcon); //eslint-disable-line
  // console.log('icon', iconNav);
  const { t } = useTranslation(); //eslint-disable-line
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  
  const Apps = session ? (
    <NavLink style={{ textDecoration: 'none' }} to="/apps">
      <NavButton color={mode === 'light' ? 'primary' : 'secondary'}>
        Apps
      </NavButton>
    </NavLink>
  ) : null;

  return (
    <Grid>
      <Grid item>
        <Box
          display={'flex'}
          justifyContent={isMd ? 'space-between' : 'space-between'}
          alignItems={'center'}
        >
          <Box
            display={'flex'}
            component="a"
            href="/"
            width={{ xs: 100, md: 120 }}
            sx={{
              textDecoration: 'none',
              alignItems: 'center',
            }}
          >
            <Box
              component={'img'}
              src={'/img/buildingDefault.png'}
              height={64}
              width={64}
            />
            <Typography
              variant="h6"
              color="text.primary"
              gutterBottom
              sx={{
                fontWeight: 600,
                marginLeft: 1,
              }}
            >
              NombreEmpresa
            </Typography>
          </Box>
          <Box display={'flex'}>
            <NavButton
              onClick={
                session
                  ? () => dispatch(logout())
                  : (event) => navigateTo(event, '/signin')
              }
              color={mode === 'light' ? 'primary' : 'secondary'}
            >
              {session ? 'Logout' : 'Login'}
            </NavButton>
            {Apps}
            <LenguageSelect />
            <ThemeModeToggler />
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          width={1}
        >
          <Box
            sx={{ display: { xs: 'none', md: 'flex' } }}
            alignItems={'center'}
          >
            <Box
              component="a"
              href="/"
              sx={{
                textDecoration: 'none',
              }}
              marginRight={{ xs: 1, sm: 2 }}
            >
              <NavItem
                title={'Home'}
                id={'Home'}
                items={home}
                colorInvert={colorInvert}
                sx={{
                  textDecoration: 'none',
                }}
              />
            </Box>
            {session && (
              <>
                <Box
                  component="a"
                  s
                  href="/tickets"
                  sx={{
                    textDecoration: 'none',
                  }}
                  marginRight={{ xs: 1, sm: 2 }}
                >
                  <NavItem
                    title={'Tickets'}
                    id={'Tickets'}
                    items={tickets}
                    colorInvert={colorInvert}
                    sx={{
                      textDecoration: 'none',
                    }}
                  />
                </Box>
                <Box
                  component="a"
                  href="/service"
                  sx={{
                    textDecoration: 'none',
                  }}
                  marginRight={{ xs: 1, sm: 2 }}
                >
                  <NavItem
                    title={'Service'}
                    id={'Service'}
                    items={service}
                    colorInvert={colorInvert}
                    sx={{
                      textDecoration: 'none',
                    }}
                  />
                </Box>
                <Box
                  component="a"
                  href="/contacts"
                  sx={{
                    textDecoration: 'none',
                  }}
                  marginRight={{ xs: 1, sm: 2 }}
                >
                  <NavItem
                    title={'Contacts'}
                    id={'Contacts'}
                    items={contacts}
                    colorInvert={colorInvert}
                    sx={{
                      textDecoration: 'none',
                    }}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box
            sx={{ display: { xs: 'block', md: 'none' } }}
            alignItems={'center'}
          >
            <Button
              onClick={() => onSidebarOpen()}
              aria-label="Menu"
              variant={'outlined'}
              sx={{
                borderRadius: 2,
                minWidth: 'auto',
                padding: 1,
                borderColor: alpha(theme.palette.divider, 0.2),
              }}
            >
              <MenuIcon />
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
