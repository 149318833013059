/* eslint-disable react/prop-types */
import React from 'react';
import { Menu , MenuItem , Box, IconButton } from '@mui/material';
// import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import LanguageIcon from '@mui/icons-material/Language';


const options = [
  'Español',
  'Inglés',
];
export default function LenguageSelect() {
  // const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const open = Boolean(anchorEl);
  //eslint-disable-next-line
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box marginRight={{ xs: 1, sm: 2 }}>
      {/* <FormControl variant="standard" size={'small'}>
        <Select
          sx={{
            '& .MuiSelect-select.MuiSelect-standard.MuiInput-input.MuiInputBase-input.MuiInputBase-inputSizeSmall': {
              paddingBottom: '0px',
            },
          }}
          autoWidth
          value={value}
          onChange={onChange}
        >
          <MenuItem value="espanol">
            <Typography fontSize="13px">{t('global.spanish')}</Typography>
          </MenuItem>
          <MenuItem value="ingles">
            <Typography fontSize="13px">{t('global.english')}</Typography>
          </MenuItem>
        </Select>

      </FormControl> */}
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{padding: '0px'}}
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

// LenguageSelect.propTypes = {
//   onChange: PropTypes.func.isRequired,
// };
