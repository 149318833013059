/* eslint-disable indent */
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  emailInputControl,
  passwordInputControl,
  verificationCodeControl,
} from 'helpers/authControl';
import useField from 'hooks/useField';
//redux
import { useSelector } from 'react-redux';
import { selectPool } from 'redux/features/poolSlice';
import { setUser } from 'redux/features/sessionSlice';
//components/ui
import { Success } from './components';
import { Input, FormNotificationError } from 'components';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';

export default function ResetPasswordForm({ stage, setStage }) {
  const { t } = useTranslation(['translation', 'resetPassword']);
  const pool = useSelector(selectPool);
  const [showError, setShowError] = useState(false);
  const email = useField('text');
  const verCode = useField('number');
  const newPassword = useField('password');
  const repeatNewPassword = useField('password');

  const firstStageSubmit = () => {
    emailInputControl(email.atributes.value)
      ? sendCode()
      : email.actions.onError(
          <FormNotificationError
            errorMsg={t('error.email', { ns: 'resetPassword' })}
          />,
        );
  };

  const sendCode = async () => {
    const user = setUser(email.atributes.value, pool);

    user.forgotPassword({
      onSuccess: (data) => {
        console.log('onSuccess: ', data);
      },
      onFailure: (err) => {
        console.log('onFailure: ', err);
      },
      inputVerificationCode: (data) => {
        console.log('Input code: ', data);
        setStage(2);
      },
    });
  };

  const handleResetPasswordErr = (err) => {
    const { name } = err;

    switch (name) {
      case 'CodeMismatchException':
        verCode.actions.onError(
          <FormNotificationError
            errorMsg={t('error.codeMismatch', { ns: 'resetPassword' })}
          />,
        );
        break;
      case 'LimitExceededException':
        setShowError(true);
        break;
    }
  };

  const inputFormControl = () => {
    const newPasswordInputValue = newPassword.atributes.value;
    const repeatNewPasswordInputValue = repeatNewPassword.atributes.value;
    const verCodeInputValue = verCode.atributes.value;

    verificationCodeControl(verCodeInputValue)
      ? verCode.actions.onSuccess()
      : verCode.actions.onError(
          <FormNotificationError
            errorMsg={t('error.code', { ns: 'resetPassword' })}
          />,
        );

    passwordInputControl(newPasswordInputValue)
      ? newPassword.actions.onSuccess()
      : newPassword.actions.onError(
          <FormNotificationError
            errorMsg={t('error.password', { ns: 'resetPassword' })}
          />,
        );

    if (newPasswordInputValue === repeatNewPasswordInputValue) {
      repeatNewPassword.actions.onSuccess();
    } else {
      newPassword.actions.onError(
        <FormNotificationError
          errorMsg={t('error.passwordMatch', { ns: 'resetPassword' })}
        />,
      );
      repeatNewPassword.actions.onError(
        <FormNotificationError
          errorMsg={t('error.passwordMatch', { ns: 'resetPassword' })}
        />,
      );
    }
  };

  const secondStageSubmit = async () => {
    const user = setUser(email.atributes.value, pool);
    const newPasswordInputValue = newPassword.atributes.value;
    const repeatNewPasswordInputValue = repeatNewPassword.atributes.value;
    const verCodeInputValue = verCode.atributes.value;

    inputFormControl();

    if (
      verificationCodeControl(verCodeInputValue) &&
      passwordInputControl(newPasswordInputValue) &&
      newPasswordInputValue === repeatNewPasswordInputValue
    ) {
      user.confirmPassword(verCodeInputValue, newPasswordInputValue, {
        onSuccess: (data) => {
          console.log('onSuccess: ', data);
          setStage(3);
        },
        onFailure: (err) => {
          handleResetPasswordErr(err);
        },
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    stage === 1 ? firstStageSubmit() : secondStageSubmit();
  };

  return (
    <Card sx={{ p: { xs: 4, md: 6 } }}>
      <form onSubmit={(event) => handleSubmit(event)}>
        {showError && (
          <Fade in={showError}>
            <Box display={'flex'} color="red" alignItems={'center'} mb={3}>
              <FormNotificationError
                errorMsg={t('error.limitExceeded', { ns: 'resetPassword' })}
              />
            </Box>
          </Fade>
        )}
        <Grid container spacing={4}>
          {stage === 1 && (
            <Input
              label={t('global.enterEmail', { ns: 'translation' })}
              atributes={email.atributes}
            />
          )}
          {stage === 2 && (
            <Fragment>
              <Input
                label={t('global.verificationCode', {
                  ns: 'translation',
                })}
                atributes={verCode.atributes}
              />
              <Input
                label={t('resetPasswordForm.newPassword', {
                  ns: 'resetPassword',
                })}
                atributes={newPassword.atributes}
              />
              <Input
                label={t('resetPasswordForm.confirmNewPassword', {
                  ns: 'resetPassword',
                })}
                atributes={repeatNewPassword.atributes}
              />
            </Fragment>
          )}
          {stage === 3 && <Success />}
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={stage === 3 ? 'center' : 'space-between'}
              width={1}
              maxWidth={600}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>
                <Button
                  size={'large'}
                  variant={'outlined'}
                  component={Link}
                  href={'/signin'}
                  fullWidth
                >
                  {t('global.backToLogin', { ns: 'resetPassword' })}
                </Button>
              </Box>
              {stage !== 3 && (
                <Button size={'large'} variant={'contained'} type={'submit'}>
                  {stage === 1 &&
                    t('emailForm.sendResetLink', { ns: 'resetPassword' })}
                  {stage === 2 &&
                    t('resetPasswordForm.resetYourPassword', {
                      ns: 'resetPassword',
                    })}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </form>
    </Card>
  );
}

ResetPasswordForm.propTypes = {
  stage: PropTypes.number.isRequired,
  setStage: PropTypes.func.isRequired,
};
