const emailRegEx =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

export function emailInputControl(email) {
  return emailRegEx.test(email);
}

export function passwordInputControl(password) {
  return password.length > 7;
}

export function verificationCodeControl(code) {
  return code.length === 6 && typeof Number(code) === 'number';
}
