/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
// import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const ToolsList = () => {
  // const theme = useTheme();
  const { t } = useTranslation('indexView');

  const tools = [
    {
      title: t('tools.titleTriiDataSync'),
      icon: '/img/triiModules/TriiDataSync.svg',
      link: '/a/dataSync'
    },    
    {
      title: t('tools.titleTriiSignature'),
      icon: '/img/triiModules/TriiSignature.svg',
      link: '/a/signature'
    }
  ];
  return (
    <>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '150px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="h2"
          style={{
            fontSize: 'clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)',
            lineHeight: '1.2222222222222223',
            marginTop: '10px',
            marginBottom: '10px',
          }}
          fontWeight={800}
        >
          {t('tools.toolsTitle')}
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {tools.map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={i}
          >
            <a href={item.link} style={{textDecoration: 'none'}}>
              <Box
                component={Card}
                padding={2}
                borderRadius={2}
                width={1}
                // height={1}
                data-aos={'fade-up'}
                data-aos-delay={i * 100}
              >
                <Box display={'flex'} alignItems={'center'}>
                  <Box
                    component="img"
                    // width={50}
                    height={50}
                    // marginBottom={2}
                    // bgcolor={theme.palette.primary.main}
                    // color={theme.palette.background.paper}
                    src={item.icon}
                  ></Box>

                  <Typography
                    variant={'h4'}
                    color={'primary'}
                    gutterBottom
                    sx={{ fontWeight: 600, fontSize: '1.1243rem !important' }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </Box>
            </a>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ToolsList;
