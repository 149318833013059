import React from 'react';
// import { Hero } from './components';
// import functionsHeroData from './utils/functionsHeroData';
import Main from 'layouts/Main';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const FormNewTicket = () => {
  const theme = useTheme('@mui/material/Theme');
  const { mode } = theme.palette;
  const { t } = useTranslation('TriiTickets'); //eslint-disable-line

  return (
    <Main>
      <Box
        height={'50vh'}
        sx={
          mode === 'light'
            ? { backgroundColor: 'rgba(247, 250, 255, 1)' }
            : { backgroundColor: 'rgba(247, 250, 255, 0.1)' }
        }
      >       
      </Box>
    </Main>
  );
};

export default FormNewTicket;
