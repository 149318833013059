import React from 'react';
import Main from 'layouts/Main';
import Box from '@mui/material/Box';

import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import { EditTicket, FormNewTicket, ListTicket, TicketMenu } from './components'; //eslint-disable-line
import { Divider } from '@mui/material';
import { useLocation } from 'react-router-dom';



const TicketsView = () => {
  const theme = useTheme('@mui/material/Theme');
  const { mode } = theme.palette;
  let location = useLocation(); //eslint-disable-line
  return (
    <Main>
      <Box
        sx={
          mode === 'light'
            ? { backgroundColor: 'rgba(247, 250, 255, 1)' }
            : { backgroundColor: 'rgba(247, 250, 255, 0.1)' }
        }
        minHeight={'650px'}
      >
        <Container>
          {location.pathname === '/tickets' && (
            <>
              <TicketMenu />
              <Divider sx={{ marginY: '10px', marginX: '10px' }} />
              <Box mb={2}>
                <ListTicket />
              </Box>
              <Box mb={2}>
                <ListTicket />
              </Box>
              <Box mb={2}>
                <ListTicket />
              </Box>
              <Box mb={2}>
                <ListTicket />
              </Box>
              <Box mb={2}>
                <ListTicket />
              </Box>

            </>
          )}
          {location.pathname === '/tickets/editTicket/1' && (
            <>
              <EditTicket />
            </>
          )}
          {location.pathname === '/tickets/newTicket' && (
            <>
              <FormNewTicket/>
            </>
          )}
        </Container>
      </Box>
    </Main>
  );
};

export default TicketsView;
