import React, { useEffect } from 'react';
import Main from 'layouts/Main';
import Box from '@mui/material/Box';

import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import { AppList, ToolsList } from './components';

//redux
import { useSelector } from 'react-redux';
import { selectSession } from 'redux/features/sessionSlice';

const AppsView = () => {
  const theme = useTheme('@mui/material/Theme');
  const { mode } = theme.palette;
  const session = useSelector(selectSession);

  useEffect(() => {
    if (!session) {
      window.location.href = '/';
    }
  }, []);

  return (
    <Main>
      <Box
        sx={
          mode === 'light'
            ? { backgroundColor: 'rgba(247, 250, 255, 1)' }
            : { backgroundColor: 'rgba(247, 250, 255, 0.1)' }
        }
        minHeight={'650px'}
      >
        <Container>
          <AppList />
        </Container>
        <Container>
          <ToolsList />
        </Container>
      </Box>
    </Main>
  );
};

export default AppsView;
