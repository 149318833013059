const pages = {
  landings: [
    {
      title: 'Inicio',
      href: '/',
    },
  ],

  homeMenu: [
    {
      title: 'Home',
      href: '/',
    },        
  ],
  ticketsMenu: [
    {
      title: 'Tickets',
      href: '/tickets',
    },
  ],
  serviceMenu: [
    {
      title: 'Service',
      href: '/service',
    },
  ],
  contactMenu: [
    {
      title: 'Contacts',
      href: '/contacts',
    },
  ],
  contact: [
    {
      title: 'Contacto',
      href: '/contacto',
    },
  ],
  modules: [
    {
      title: 'Trii Chat',
      href: '/a/chat',
    },
    {
      title: 'Trii Contactos',
      href: '/a/contacts',
    },
    {
      title: 'Trii Cobranzas',
      href: '/a/collection',
    },
    {
      title: 'Trii Marketing',
      href: '/a/marketing',
    },
    {
      title: 'Trii Llamadas',
      href: '/a/calls',
    },
    {
      title: 'Trii Service',
      href: '/a/service',
    },
    {
      title: 'Trii Team',
      href: '/a/team',
    },
    {
      title: 'Trii Ticket',
      href: '/a/tickets',
    },
  ],
  functions: [
    {
      title: 'Funciones',
      href: '/funciones',
    },
  ],
};

export default pages;
