import React, { useContext, useEffect, useState } from 'react';
import { StyleContext } from '../../../../style/styleProvider';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';

const ListTicket = () => {
  const { fontSize, fontWeight, shadow } = useContext(StyleContext);
  const [show, setShow] = useState(false);
  const navigate = useNavigate(); //eslint-disable-line
  const theme = useTheme();

  useEffect(() => {
    setShow(true);
  }, []);
  //eslint-disable-next-line
  const openEditTicket = () => {
    // navigate('/tickets/editTicket/1');
    console.log('open');
  };

  return (
    <>
      <Fade style={{ transitionDuration: '750ms' }} in={show}>
        <Box className="py-2" bgcolor={theme.palette.background.default}>
          <Box
            className="panel-inner my-0"
            display={'flex'}
            boxShadow={shadow.md}
            borderRadius={1}
            color="text.primary"
            // py={{ md: '1.1rem', lg: '1.4rem' }}
            px={{ md: '1rem', lg: '1rem' }}
            gridcolumngap={{ md: '7rem', lg: '13rem' }}
          >
            <Box  display="flex" justifyContent="space-between" width="100%">
              <>
                <Box  mr="auto" py={1} pr={4} >
                  <Typography
                    fontWeight={fontWeight.semibold}
                    fontSize={fontSize.sm}
                  >
                    Prueba Lista
                    {/* {item.name} */}
                  </Typography>
                  <br></br>
                  <Typography
                    fontWeight={fontWeight.normal}
                    fontSize={fontSize.sm}
                  >
                    {/* {item.domain_active} */}0 contactos
                  </Typography>
                  <Typography
                    fontWeight={fontWeight.normal}
                    fontSize={fontSize.xs}
                  >
                    {/* {item.domain_active} */}
                    Creado el 6/9/2021 09:36:28
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="end"
                  justifyContent="space-evenly"
                >
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                  <Button
                    style={{ alignSelf: 'center' }}
                    variant="contained"
                    size="small"
                    onClick={openEditTicket}
                  >
                    Modificar
                  </Button>
                </Box>
              </>
            </Box>
          </Box>
        </Box>
      </Fade>
    </>
  );
};

export default ListTicket;
